import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'fogg/ui';

import 'assets/stylesheets/application.scss';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Jumbotron from 'components/Jumbotron';

const ErrorLayout = ({ children, code }) => {
  return (
    <Layout>
      <Jumbotron>
        <h1>{code}</h1>
      </Jumbotron>
      <Container className="align-center">
        {children}
        <p>
          <Button to="/">Home</Button>
        </p>
      </Container>
    </Layout>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ErrorLayout;
